.SongWriterMultiSelector {
    &-title {
        font-size: 12px;
        padding-top: 5px;
    }

    &-subtitle {
        color: var(--gray-600);
        font-size: 11px;
    }
}
