.SongWriterListPage {
    font-size: 12px;

    td {
        vertical-align: middle;
    }

    &-not-registered {
        color: var(--gray-600);

        .WarningGlyphIcon {
            margin-left: 4px;
            color: var(--glyphs-warning);
        }
    }

    &-pill {
        margin: 5px;
    }

    &-pill-zero {
        margin: 0 15px;
    }

    &-link-list {
        max-height: 200px;
    }
    &-link {
        cursor: pointer;
        color: var(--blue);
    }
    &-pka {
        color: var(--gray-600);
        font-size: 11px;
    }
    &-legal-name-column {
        width: 25%;
    }

    .cell-table-new-actions {
        display: flex;
        gap: 10px;
    }
    .new-action-btn {
        width: 32px;
        height: 32px;
        background: none;
        background-color: #cee3f3;
        color: #1d4f72;
    }
    .new-action-btn:not(:disabled):not(.loading):hover {
        background-color: #bad8ee;
        box-shadow: none;
        scale: 1;
    }
    .TableActionButton:hover:not(:disabled) > .Icon {
        scale: 1;
    }
    .TableActionButton:focus {
        outline: none;
        box-shadow: none !important;
    }
}
